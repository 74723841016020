import React from 'react';
import { Divider, List } from 'antd';
import { Link, graphql } from 'gatsby';
import * as _ from 'lodash';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Header = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;

const AppGroupContainer = styled.div`
  margin-bottom: 20px;
`;

const ListItem = styled(List.Item)`
  display: flex;
  flex-direction: row-reverse;
  
  &:hover {
    background: #f5f5f5;
  }
  
  .ant-list-item-extra {
    margin-right: 40px !important;
    margin-left: 0 !important;
  }
  
  @media screen and (max-width: 576px) {
    .ant-list-item-extra {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
`;

const AppImage = styled.img`
  width: 170px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;

function IndexPage({ data }) {
  const appGroups = _.sortBy(data.allAppGroup.nodes, [(group) => group.index]);

  return (
    <Layout>
      <SEO title="Trang chủ" />
      <Header>Bạn đang cần tính toán gì?</Header>
      {_.map(appGroups, appGroup => {
        return (
          <AppGroupContainer key={appGroup.slug}>
            <Divider orientation="left">
              <h2>{appGroup.name}</h2>
            </Divider>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={appGroup.apps}
              renderItem={app => (
                <React.Fragment key={app.name}>
                  <Link to={`/${process.env.GATSBY_APP_SLUG}/${app.fullSlug}`}>
                    <ListItem
                      extra={
                        <AppImage alt={app.slug} src={app.base64Image} />
                      }
                    >
                      <List.Item.Meta
                        title={app.name}
                        description={app.description}
                      />
                    </ListItem>
                  </Link>
                  {app !== appGroup.apps[appGroup.apps.length - 1] && (
                    <div style={{paddingLeft: 20, paddingRight: 20}}>
                      <Divider dashed />
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          </AppGroupContainer>
        );
      })}
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allAppGroup: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
          apps: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              slug: PropTypes.string,
              fullSlug: PropTypes.string,
              description: PropTypes.string,
              base64Image: PropTypes.string,
            })
          ),
        })
      ),
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allAppGroup {
      nodes {
        index
        slug
        name
        apps {
          name
          slug
          fullSlug
          description
          base64Image
        }
      }
    }
  }
`;
